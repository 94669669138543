import React, {useEffect, useState} from "react";

type InfoProps = {
    bpk : string,
    cName : string,
    cCell : string,
    cJumin : string,
    cMail : string,
    cPost : string,
    cAddr1 : string,
    cAddr2 : string,
    cJob : string,
}
const JuminInput = (props : {info : InfoProps, setInfo : React.Dispatch<React.SetStateAction<InfoProps>>}  ) => {
    const [birth , setBirth] = useState<string>('');
    const [jumin, setJumin] = useState<string>('')
    const [check, setCheck] = useState<boolean>(false);
    const juminRegex = /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]\d{6}/g;
    const onChangeHandler = (e : React.ChangeEvent<HTMLInputElement>) => {
        const {id} = e.target;
        const {value} = e.target;
        const onlyNumber = value.replace(/[^-0-9]/g, '');
        if(id === 'birth') {
            setBirth(onlyNumber);
        }
        if(id === 'jumin'){
            setJumin(onlyNumber);
        }
    }
    useEffect(() => {
        if((birth + jumin).length === 13) {
            let juminAll = birth + jumin;
            console.log(juminAll);
            if (juminRegex.test(juminAll)) {
                setCheck(true);
                props.setInfo((prevState) => {
                    return { ...prevState, cJumin: juminAll }
                });
            } else {
                setCheck(false);
            }
        }
    }, [birth,jumin]);
    return(
        <div className='flex flex-col pb-8'>
            <div className='sub_title pb-1 pl-4'>
                주민번호
            </div>
            <div className='flex mx-2'>
                <input type={'text'} id={'birth'} maxLength={7} value={birth} onChange={onChangeHandler} className='input w-[150px] mr-4' placeholder='앞자리'/>
                <input type={'password'} maxLength={7} id={'jumin'} value={jumin} onChange={onChangeHandler} className='input w-[150px]' placeholder='뒷자리'/>
            </div>
            {!check && jumin !== '' && <h2 className='text-red-500 text-[11px] pt-2 pl-4'>주민번호를 확인해주세요.</h2>}
        </div>
    )
}
export default JuminInput;