import MainText from "../molecules/mainText";
import ApplyButton from "../atoms/applyButton";
import MainTopGroup from "../organisms/mainTopGroup";
const Main = () => {
    return(
        <div>
            <MainTopGroup />
            <MainText />
            <ApplyButton />
        </div>
    )
}
export default Main;