import React from "react";
import {useNavigate} from "react-router-dom";

const ApplyButton = () => {
    const page = useNavigate();
    const onClickHandler = (e : React.MouseEvent<HTMLElement>) => {
        page('certification');
    }
    return (
        <div className='fixed left-0 bottom-0 w-full bg-[#f6fbf7]'>
            <div className='border mx-8 my-8 py-[10px] px-4 rounded-lg bg-[#32a087] text-white font-bold text-[14px] flex items-center justify-center'>
                <button onClick={(e) => onClickHandler(e)}>이륜자동차 운전자보험 가입 신청하기</button>
            </div>
        </div>
    )
}
export default ApplyButton;