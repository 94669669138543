import React from "react";
import DbIcon from '../../assets/images/db_BI.png';
import { useNavigate } from 'react-router-dom';

const LogoButton = () => {
    const page = useNavigate();
    const onClickHandler = (e : React.MouseEvent<HTMLElement>) => {
        page('/');
    }

    return (
            <button onClick={onClickHandler} className='base_button'>
                <img src={DbIcon} alt={'homeBtn'} className='w-24'/>
            </button>

    )
}
export default LogoButton;