import React, {useEffect, useState} from "react";

type InfoProps = {
    bpk : string,
    cName : string,
    cCell : string,
    cJumin : string,
    cMail : string,
    cPost : string,
    cAddr1 : string,
    cAddr2 : string,
    cJob : string,
}
const NameInput = (props : {info : InfoProps, setInfo : React.Dispatch<React.SetStateAction<InfoProps>>}  ) => {
    const [name , setName] = useState<string>('');
    const [check, setCheck] = useState<boolean>(false);
    const onChangeHandler = (e : React.ChangeEvent<HTMLInputElement>) => {
        const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z]+$/;
        setName(e.currentTarget.value);
        if(nameRegex.test(name)){
            setCheck(true);
        }else {
            setCheck(false);
        }
    }
    useEffect(() => {
        if(check){
            props.setInfo((prevState) => {
                return { ...prevState, cName: name }
            });
        }
    }, [name]);
    return(
        <div className='pt-4 flex flex-col pb-8'>
            <div className='sub_title pb-1 pl-4'>
                이름
            </div>
            <input type={'text'} onChange={onChangeHandler} className='input ml-2' placeholder='기사 성명'/>
            {!check && name !== '' && <h2 className='text-red-500 text-[11px] pt-2 pl-2'>이름을 확인해주세요.</h2>}
        </div>
    )
}
export default NameInput;