import TopButtonGroup from "../organisms/topButtonGroup";
import ProgressBar from "../atoms/progressBar";
import CertiText from "../atoms/certiText";
import AgreeAll from "../atoms/agreeButtons/agreeAll";
import AgreeButton from "../atoms/agreeButtons/agreeButton";
import AgreeButtoGroup from "../molecules/agreeButtonGroup";
import FooterGroup from "../molecules/footerGroup";
import CertiFormGroup from "../molecules/certiFormGroup";
import {useState} from "react";

const Certification = () => {
    const step = 'step1'
    const [check, setCheck] = useState<boolean>(false);

    return (
        <div className='flex flex-col w-full'>
            <TopButtonGroup step={step}/>
            <ProgressBar select={'certi'}/>
            <AgreeButtoGroup setCheck={setCheck}/>
            <CertiText/>
            <CertiFormGroup check={check}/>
            <FooterGroup />
        </div>
    )
}
export default Certification;