import InfoIcon from '../../assets/images/icon_info.svg';
import CheckIcon from '../../assets/images/icon_checkbox_14px_on.svg';
import ProgressBar from "../atoms/progressBar";
import styled from "styled-components";

const StyledTable = styled.table`
  tbody > tr td {
    border-top:2px #f5f5f5 solid;
    padding : 6px;
  }
  tbody > tr > td:nth-child(even) {
    text-align: end;
  }
  tbody > tr > td:nth-child(odd) {
    text-align: start;
    padding-left : 6px;
  }
  tbody > tr:first-child td {border:none}
  thead > tr > td {
    padding : 8px;
    width : 50%;
  }
`
const MainText = () => {
    return (
        <div className='main'>
            <ProgressBar select={'main'}/>
            <div className='flex-col overflow-y-scroll pb-[90px]'>
            <div className='title_text mt-4 flex-col flex justify-center items-center'>
                <h2>이륜자동차 운전자 보험이란?</h2>
                <span className='text-[14px] text_color'>나를 지키기 위한 보험</span>
            </div>
            <ul className='w-screen px-10 list-outside base_text mt-4 items-start' style={{ listStyleImage: `url(${InfoIcon})`}}>
                <li className='leading-[20px]'>
                     이륜차 의무보험이 상대방에 대한 보상을 담보한다면 이륜차 운전자 보험은 나의 부상과 금전적 손해 그리고 형사상 책임을 담보합니다.
                </li>
                <li className='leading-[20px] mt-2'>
                    이륜자동차 운전자 보험은 의무가입은 아니되, 사고 위험에 많이 노출된 배달 업무를 하시는 분들께 추천되는 상품입니다.
                </li>
            </ul>
            <div className='flex flex-col w-screen mt-4 px-4'>
                <div className='sub_title flex w-full'>
                    <div className='flex ml-0'>
                        <img src={CheckIcon} alt={'check'}  className='pr-1'/>
                        <h2>담보내용</h2>
                    </div>
                    <div className='ml-auto text-[10px] pt-1 font-semibold'><h2>단위 : 만원</h2></div>
                </div>
                <StyledTable className='border  text-center mt-2 rounded-lg'>
                    <thead className='bg-[#f6fbf7] border-b-[#157b4b] border-b-[2px]'>
                    <tr className='sub_title text-[11px]'>
                        <td className='border-r-[2px] border-r-[#157b4b]'>
                            담보/플랜
                        </td>
                        <td>
                            신규 가입
                        </td>
                    </tr>
                    </thead>
                    <tbody className='text-[10px]'>
                    <tr>
                        <td>
                            사고 상해사망
                        </td>
                        <td>
                            1000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            사고 휴우장애 3%
                        </td>
                        <td>
                            1000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            사고처리지원금
                        </td>
                        <td>
                            3000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            사고처리지원금
                            <br/>
                            (중대법규위반,6주미만 치료)
                        </td>
                        <td>
                            500
                        </td>
                    </tr>
                    <tr>
                        <td>
                            자동차사고 변호사선임 비용
                        </td>
                        <td>
                            3000
                        </td>
                    </tr>
                    <tr>
                        <td>
                            입원일당(1 - 180일)
                        </td>
                        <td>
                            2
                        </td>
                    </tr>
                    </tbody>
                </StyledTable>
            </div>
            <div className='flex flex-col w-screen mt-4 px-4'>
                <div className='sub_title flex'>
                    <img src={CheckIcon} alt={'check'}  className='pr-1'/>
                    <h2>유의 사항</h2>
                </div>
                <ul className='base_text pl-2 text-red-500'>
                    <li className='mt-2 leading-normal'>
                        유의사항1
                    </li>
                    <li className='leading-normal'>
                        유의사항2
                    </li>
                    <li className='leading-normal'>
                        유의사항3
                    </li>
                </ul>
            </div>
            </div>
        </div>
    )
}
export default MainText;