import CheckOff from '../../../assets/images/icon_checkbox_16px_off.svg';
import CheckOn from '../../../assets/images/icon_checkbox_16px_on.svg';
import React from "react";

interface AllstatusProps {
    all : boolean,
    setAll : React.Dispatch<React.SetStateAction<boolean>>
}
const AgreeAll = ({all, setAll} : AllstatusProps) => {
    const onClickHadnler = (e : React.MouseEvent<HTMLElement>) => {
        setAll(!all);
    }
    return(
        <div className='flex items-center mt-8 border-b pb-1'>
            <div className={all ? 'checkOn' : 'checkOff'}>
                <button onClick={onClickHadnler}>
                    <img src={all ? CheckOn : CheckOff} alt={'off'}/>
                </button>
            </div>
            <h2 className='title_text text-[15px] ml-2 pb-1'>[필수] 고객정보 관리 및 가입설계를 위한 동의</h2>
        </div>
    )
}
export default AgreeAll;