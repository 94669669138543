import TopButtonGroup from "../organisms/topButtonGroup";
import FooterGroup from "../molecules/footerGroup";
import ProgressBar from "../atoms/progressBar";
import InputForm from "../molecules/inputForm";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../pages/reducers";
import {useNavigate} from "react-router-dom";
import ConfirmButton from "../atoms/confirmButton";
import ApplyButton from "../atoms/applyButton";
import RegisterButton from "../atoms/registerButton";
import SelectBpk from "../atoms/inputs/selectBpk";

const RegisterPage = () => {
    const step = 'step2';
    const movePage = useNavigate();
    const  cell    = useSelector((state : RootState ) => state.userInfo.cell);
    useEffect(() => {
        if(cell === '') {
            movePage('/certification');
        }
    }, []);
    return(
        <div className='flex flex-col'>
            <TopButtonGroup step={step}/>
            <ProgressBar select={'apply'}/>
            <InputForm cell={cell}/>
            <FooterGroup />
        </div>
    )
}
export default RegisterPage;