import NameInput from "../atoms/inputs/nameInput";
import ReadPhone from "../atoms/inputs/readPhone";
import React, {useEffect, useState} from "react";
import JuminInput from "../atoms/inputs/juminInput";
import EmailInput from "../atoms/inputs/emailInput";
import JobInput from "../atoms/inputs/jobInput";
import PostCode from "../atoms/modal/postCode";
import RegisterButton from "../atoms/registerButton";
import SelectBpk from "../atoms/inputs/selectBpk";

interface PhoneProps{
    cell : string
}

const InputForm = ({cell} : PhoneProps) => {
    const [info, setInfo] = useState(
        {
            bpk : '',
            cName : '',
            cCell : cell,
            cJumin : '',
            cMail : '',
            cPost : '',
            cAddr1 : '',
            cAddr2 : '',
            cJob : ''
        }
    );
    useEffect(() => {
        console.log(info);
    }, [info]);
    return (
        <div className='mx-4'>
            <SelectBpk info={info} setInfo={setInfo}/>
            <NameInput info={info} setInfo={setInfo}/>
            <ReadPhone cell={cell} />
            <JuminInput info={info} setInfo={setInfo} />
            <EmailInput info={info} setInfo={setInfo}/>
            <PostCode info={info} setInfo={setInfo} />
            <JobInput info={info} setInfo={setInfo}/>
            <RegisterButton info={info}/>
        </div>
    )
}
export default InputForm;