import { produce } from 'immer';

const USER_PHONE = 'userinfo/USER_PHONE';

export const userPhone = (  cell: string ) => ({
    type : USER_PHONE,
    cell
});

type PhoneType = {
    cell : string
}

const initialState : PhoneType = {
   cell : ''
};

function userInfo(state = initialState, action: { type: any; cell : string })  {
    switch (action.type){
        case  USER_PHONE :
            return produce(state, draft => {
                draft.cell = action.cell
            });
        default : return state;
    }
}
export default userInfo;