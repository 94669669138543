import React, {Dispatch, SetStateAction, useState} from "react";

interface Props {
    setCell: React.Dispatch<React.SetStateAction<string>>;
    setIsCell : React.Dispatch<React.SetStateAction<boolean>>;
}
const PhoneInput = ({setCell, setIsCell} : Props) => {
    const [phone, setPhone] = useState<string>('');
    const [isCheck, setIsCheck] = useState<boolean>(false);
    const onChangeHandler = (e : React.ChangeEvent<HTMLInputElement>) => {
        const phoneRegex =/^\d{3}\d{3,4}\d{4}$/;
        const { value } = e.target;
        const onlyNumber = value.replace(/[^-0-9]/g, '');
        setPhone(onlyNumber);
        if(phoneRegex.test(onlyNumber)){
            setIsCheck(true);
            setIsCell(true);
            setCell(onlyNumber);
        }else {
            setIsCheck(false);
            setIsCell(false);
        }
    }
    return (
        <div>
            <input placeholder='휴대폰 번호를 입력해주세요' className='input'  value={phone} type='string' onChange={(e) => onChangeHandler(e)}/>
            <h2 className='text-gray-500/50 font-semibold text-[12px] pt-1'>(-)없이 입력해주세요.</h2>
            {!isCheck && phone !== '' && <h2 className='text-red-500 text-[12px]'>휴대폰 번호 형식이 맞지 않습니다.</h2>}
        </div>
    )
}
export default PhoneInput;