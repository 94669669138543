import axios, {AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders,} from 'axios'
/** Axios Response 데이터 형식
 *  config : 요청에 대한 axios 구성 설정
 *  data 서버가 제공한 응답 데이터
 *  headers : 헤더 정보
 *  request : 요청
 *  status : 응답 HTTP 상태 코드
 *  statusText : 응답 HTTP 상태 메시지
 */

    // 본인 서버에서 내려주는 응답 구조
interface AxiosResponse<T = any, D = any> {
    data: T
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<D>
    request?: any
}


type ParamProps = {
    bpk : string,
    cName : string,
    cCell : string,
    cJumin : string,
    cMail : string,
    cPost : string,
    cAddr1 : string,
    cAddr2 : string,
    cJob : string
}
const baseUrl : any = process.env.REACT_APP_BASE_JOIN;
export async function sendJoin(param : ParamProps)  {
    let apiKey : any = '';
    if(param.bpk === 'onna') apiKey = process.env.REACT_APP_X_API_SECRET_ONNA;
    if(param.bpk === 'enuri') apiKey = process.env.REACT_APP_X_API_SECRET_ENURI;
    if(param.bpk === 'barico') apiKey = process.env.REACT_APP_X_API_SECRET_BARICO;
    try {
        const res = await axios.post<AxiosResponse<any>>(baseUrl, param,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-API-SECRET' : apiKey
                }
            });
        if (res.status === 200) {
            return {status : res.status};
        }
    } catch (error) {
        return {status : error , msg : '서버오류 : [ 1877-3006 ] 고객센터에 문의바랍니다.'};
    }
}
