import React, {useState} from "react";
type InfoProps = {
    bpk : string,
    cName : string,
    cCell : string,
    cJumin : string,
    cMail : string,
    cPost : string,
    cAddr1 : string,
    cAddr2 : string,
    cJob : string,
}
const JobInput =(props : {info : InfoProps, setInfo : React.Dispatch<React.SetStateAction<InfoProps>>}) => {
    const [job, setJob] = useState<string>('');
    const onChangeHandler = (e : React.ChangeEvent<HTMLInputElement>) => {
            setJob(e.target.value);
            props.setInfo((prevState) => {
                return { ...prevState, cJob: e.target.value }
            });
    }
    return(
        <div className='flex flex-col pb-2 pt-4'>
            <div className='sub_title pb-1 pl-4'>
                직업
            </div>
            <div className='flex justify-between mx-2'>
                <input type={'text'} name={job} onChange={onChangeHandler} className='input basis-1/3' placeholder='직업을 입력해주세요'/>
            </div>
        </div>
    )
}
export default JobInput;