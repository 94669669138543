import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {sendJoin} from "../../pages/api/sendJoin";
import Modal from "react-modal";
type InfoProps = {
    bpk : string,
    cName : string,
    cCell : string,
    cJumin : string,
    cMail : string,
    cPost : string,
    cAddr1 : string,
    cAddr2 : string,
    cJob : string,
}
const RegisterButton = (props : {info : InfoProps} ) => {
    const page = useNavigate();
    const [msg, setMsg] = useState<string>('');
    const [flag, setFlag] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<boolean>(false);
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
        },
        content: {
            left: "50%",
            margin: "auto",
            width: "350px",
            height: "200px",
            padding: "0",
            overflow: "hidden",
            transform : "translate(-50%,0)"
        },
    };
    const onClickHandler = (e : React.MouseEvent<HTMLElement>) => {
        let { id } : any = e.target;
        if(id === 'reg'){
            let idx: keyof typeof props.info;
            let check : string = '';
            for(idx in props.info){
                if(props.info[idx] === '' && idx !== 'cMail'){
                    check = idx;
                    if(check === 'bpk') setMsg( '업체를 선택해주세요.');
                    if(check === 'cName') setMsg('이름을 입력해주세요.');
                    if(check === 'cCell') setMsg('전화번호를 입력해주세요.');
                    if(check === 'cJumin') setMsg('주민번호를 입력해주세요.');
                    if(check === 'cPost') setMsg('주소를 입력해주세요.');
                    if(check === 'cJob') setMsg('직업을 입력해주세요.');
                    if(check === 'cAddr1') setMsg('주소를 입력해주세요.');
                    if(check === 'cAddr2') setMsg('주소를 입력해주세요.');
                    break;
                }
            }
            if(check === ''){
                sendJoin(props.info)
                    .then((res : any) => {
                        console.log(res);
                        if(res.status === 200){
                            setFlag(true);
                        }else {
                            alert(res.msg);
                        }
                    })
            }else {
                setErrMsg(true);

            }
        }
        if(id === 'confirm'){
            page('/');
        }
    }
    useEffect(() => {

    }, [props.info]);
    return (
        <div className='w-full pb-[170px]'>
            <div className='border mx-8 my-8 py-[10px] px-4 rounded-lg bg-[#32a087] text-white font-bold text-[14px] flex items-center justify-center'>
                <button id={'reg'} className='w-full' onClick={onClickHandler}>신청하기</button>
            </div>
            {   flag &&
                    <Modal isOpen={flag} style={customStyles}>
                        <div className='border-b bg-[#f6fbf7] border-b-[#157b4b] font-bold'>
                            <div className='flex justify-center py-2'>
                                가입 신청 완료
                            </div>
                        </div>
                        <div className='flex-col justify-center text-center text-[14px] font-semibold'>
                            <div className='pt-4'>
                                감사합니다!
                            </div>
                            <div>
                                이륜차 운전자 보험 가입 신청이 완료되었습니다.
                            </div>
                            <div>
                                기입해주신 번호로 담당자가 곧 연락드릴 예정입니다
                            </div>
                        </div>
                        <div className='fixed bottom-0 w-full flex justify-center py-2'>
                            <button id={'confirm'} className='button_on' onClick={onClickHandler}>확인</button>
                        </div>
                    </Modal>
            }
            {   errMsg &&
                <Modal isOpen={errMsg} style={customStyles}>
                    <div className='border-b bg-[#f6fbf7] border-b-[#157b4b] font-bold'>
                        <div className='flex justify-center py-2'>
                            잘못된 입력
                        </div>
                    </div>
                    <div className='flex justify-center mt-8'>
                        <h2>
                            {msg}
                        </h2>
                    </div>
                    <div className='fixed bottom-0 w-full flex justify-center py-2'>
                        <button id={'confirm'} className='button_on' onClick={(e : React.MouseEvent<HTMLElement>)=>setErrMsg(false)}>확인</button>
                    </div>
                </Modal>
            }
        </div>
    )
}
export default RegisterButton;