import SimgIcon from '../../assets/images/simg_logo.png'
const FooterGroup = () => {
    return(
            <div className='footer text_white items-end'>
                <div className='flex basis-1/2 flex-col mr-auto items-start'>
                    <h2 className='h2_pb1'>보험대리점 : (주)SIMG</h2>
                    <h2 className='h2_pb1'>대표자 : 오성준</h2>
                    <h2 className='h2_pb1'>사업자번호 : 128-87-16058</h2>
                    <h2 className='h2_pb1'>주소 : 서울특별시 서초구 논현로 173, 2층(제이빌딩)</h2>
                    <h2>SIMG InsuranceCo, Ltd.ALL RIGHTS RESERVED.</h2>
                </div>
                <div className='mr-4'>
                    <img alt={'simgLogo'} src={SimgIcon} className='w-14'/>
                </div>
        </div>
    )
}
export default FooterGroup;