import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Register from "./pages/MyPage/register";
import Certification from "./components/Template/certification";
import RegisterForm from "./components/Template/registerPage";
import PostCode from "./components/atoms/modal/postCode";
import Modal from "react-modal";

function App() {

  return (
    <div className="App">
      <BrowserRouter >
        <Routes>
          <Route path="/" element={<Register />} />
            <Route path="/certification" element={<Certification />} />
            <Route path="/register" element={<RegisterForm/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
Modal.setAppElement('#root')
export default App;
