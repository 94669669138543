type SelectProps = {
    select : string
}
const ProgressBar = ({ select } : SelectProps) => {
    return (
        <div className='w-full flex justify-center'>
            {
                select === "main" &&
                <div className="flex w-[250px] justify-between items-center relative z-1 before_line">
                    <div className='text-[12px] border px-4 py-2 rounded-2xl text-white bg-[#32A086] absoulte'>1.가입 안내</div>
                    <div className='progressBorder border-[#157b4b] absoulte'></div>
                    <div className='progressBorder border-[#157b4b] absoulte'></div>
                </div>
            }
            {
                select === "certi" &&
                    <div className="flex w-[250px] justify-between items-center relative z-1 before_line pt-[70px]">
                    <div className='progressBorder border-[#157b4b] absoulte'></div>
                    <div className='text-[12px] border px-4 py-2 rounded-2xl text-white bg-[#32A086] absoulte'>2.휴대폰 인증</div>
                    <div className='progressBorder border-[#157b4b] absoulte'></div>
                    </div>
            }
            {
                select === "apply" &&
                <div className="flex w-[250px] justify-between items-center relative z-1 before_line pt-[70px]">
                    <div className='progressBorder border-[#157b4b] absoulte'></div>
                    <div className='progressBorder border-[#157b4b] absoulte'></div>
                    <div className='text-[12px] border px-4 py-2 rounded-2xl text-white bg-[#32A086] absoulte'>3.가입정보 입력</div>
                </div>
            }
        </div>
    )
}
export default ProgressBar;