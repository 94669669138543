import BackIcon from '../../assets/images/btn_back.svg';
import React from "react";
import {useNavigate} from "react-router-dom";

interface PropsType{
    step : string
}
const BackButton = ({step} : PropsType ) => {
    const movePage = useNavigate();
    const onClickHandler = (e : React.MouseEvent<HTMLElement>) => {
        if(step === 'step1') movePage('/');
        if(step === 'step2') movePage('/certification');
        if(step === 'step3') movePage('/register');
    }
    return (
        <div>
            <button className='base_button '>
                <img src={BackIcon} alt={'backIcon'}  onClick={onClickHandler}/>
            </button>
        </div>
    )
}
export default BackButton;