import React, {useState} from "react";
import CloseIcon from '../../../assets/images/btn_close_24px.svg';
interface ShowProps {
    sel : boolean,
    setSel : React.Dispatch<React.SetStateAction<boolean>>
}
const AgreeText = ({sel, setSel} : ShowProps) => {
    const [close, setClose] = useState(sel);
    const onClickHandler = (e : React.MouseEvent<HTMLElement>) => {
        setClose(!close);
        setTimeout(() => {
            setSel(!sel);
        }, 500);
    }
    return(
        <div>
            <div className='fixed inset-0 w-full z-10 '>
                <div className={close ? 'absolute bg-white bottom-0 left-0 w-screen h-full bg-white animate-[left-side-on_600ms_ease-in-out] overflow-y-auto' : 'absolute bottom-0 h-full left-0  w-screen bg-white animate-[left-side-out_600ms_ease-in-out]'}>
                    <div className='flex items-center mb-8 text-[14px] h-[45px] bg-[#f6fbf7] border-b-[2px] border-b-[#157b4b] title_text fixed top-0 w-full'>
                        <div className="flex w-full justify-center">소비자 권익보호에 관한 사항</div>
                        <div className='mr-2'> <button onClick={(e) => onClickHandler(e)}><img src={CloseIcon}/></button></div>
                    </div>
                    <div className='text-[11px] mx-8 flex justify-center flex-col pt-[60px]'>
                        <div className='bold_12'>
                            개인(신용)정보의 수집ㆍ이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의를 철회할 수 있습니다.
                        </div>
                        <div className='bold_12 mt-1'>
                            다만, 본 동의는 ‘고객정보 관리 및 가입설계‘를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무수행이 불가능합니다.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgreeText;