import BackButton from "../atoms/backButton";
import LogoButton from "../atoms/logoButton";

interface StepProps {
    step : string
}
const TopButtonGroup = ({step} : StepProps) => {
    return(
        <div className='topBar'>
            <div className='mr-auto pl-2 pt-1'>
                <BackButton step={step}/>
            </div>
            <div className='mr-auto'>
                <LogoButton />
            </div>
        </div>
    )
}
export default TopButtonGroup;