import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import {userPhone} from "../../pages/reducers/userInfo";

interface Props{
    code : string,
    inputCode : string,
    isCode : boolean,
    cell : string,
    check : boolean
}
const ConfirmButton = ({code, inputCode, isCode, cell, check} : Props) => {
    const movePage = useNavigate();
    const dispatch = useDispatch();
    const onClickHandler = (e : React.MouseEvent<HTMLElement>) => {
        if(check){
            if(code !== inputCode || inputCode === ''){
                alert('잘못된 인증번호 입니다.');
            }else{
                movePage('/register');
                dispatch(userPhone(cell));
            }
        }else {
            alert("필수 확인사항, 약관에 동의해주세요.");
        }
    }
    useEffect(() => {

    }, [inputCode]);
    return (
        <div>
            <button className={isCode ? 'button_on' : 'button_off'} onClick={onClickHandler} disabled={!isCode}>확인</button>
        </div>
    )
}
export default ConfirmButton;